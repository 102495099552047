import React from "react";
import { Analytics } from '@vercel/analytics/react';

import {
    Header,
    About,
    Work,
    Skills,
    Contact,
} from "./containers";

import { Navbar, Footer } from "./components";
import "./App.scss";

const App = () => {
    return (
        <div className="app">
            <Navbar />
            <Header />
            <About />
            <Work />
            <Skills />
            <Contact />
            <Footer />
            <Analytics />
        </div>
    );
};

export default App;
import React from "react";
import { BsHouseDoorFill } from "react-icons/bs";
import { GrLinkedinOption } from "react-icons/gr";
import { FaGithub } from "react-icons/fa";
import { motion } from "framer-motion";

import "./Footer.scss";
const Footer = () => {
  return (
    <div className="app__flex app__footer">
      <motion.div className="app__footer-contacts app__flex">
        <div className="app__flex">
          <a href="https://www.linkedin.com/in/warrengalyen" target="_blank" rel="noreferrer" className="app__flex">
            <GrLinkedinOption />
          </a>
        </div>
        <div className="app__flex">
          <a href="https://www.github.com/warrengalyen" target="_blank" rel="noreferrer" className="app__flex">
            <FaGithub />
          </a>
        </div>
        <div className="app__flex">
          <a href="https://www.mechanikadesign.com" target="_blank" rel="noreferrer" className="app__flex">
            <BsHouseDoorFill />
          </a>
        </div>
      </motion.div>
      <div className="app__footer-copyrights">
        <p className="p-text">&copy; 2023 Warren Galyen</p>
        <p className="p-text">ALL RIGHTS RESERVED</p>
      </div>
    </div>
  );
};

export default Footer;

import figma from "../assets/figma.png";
import javascript from "../assets/javascript.png";
import node from "../assets/node.png";
import python from "../assets/python.png";
import react from "../assets/react.png";
import redux from "../assets/redux.png";
import sass from "../assets/sass.png";
import typescript from "../assets/typescript.png";
import vue from "../assets/vue.png";

import aboutImg from "../assets/aboutImg.svg";

import steamman from "../assets/steamman.png";
import programmer from "../assets/programmer.svg";
import circle from "../assets/circle.svg";
import logo from "../assets/logo.png";

const images = {
    figma,
    javascript,
    node,
    python,
    react,
    redux,
    sass,
    typescript,
    vue,
    aboutImg,
    steamman,
    programmer,
    circle,
    logo,
};

export default images;
import React, { useState } from "react";

// import { images } from "../../constants";
import { AppWrap, MotionWrap } from "../../wrapper";
import emailjs from "emailjs-com";

import "./Contact.scss";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setIsLoading] = useState(false);

  const { email, message, subject, name } = formData;

  const handleChangeInput = (e) => {
    const { name: fieldName, value } = e.target;

    setFormData((prev) => {
      return { ...prev, [fieldName]: value };
    });
  };

  const handleSubmit = () => {
    setIsLoading(true);

    const contact = {
      _type: "contact",
      name: name,
      email: email,
      subject: subject,
      message: message,
    };

    emailjs.send("service_ci2gkws", "template_mucd46j", contact, "qkuL4t85JTJW6GPiY").then(
      (result) => {
        setIsLoading(false);
        setIsFormSubmitted(true);
      },
      (error) => {
        console.log(error.text);
        setIsLoading(false);
        setIsFormSubmitted(false);
      }
    );
  };

  return (
    <>
      <h2 className="head-text">
        Take a moment to <span>chat</span> with <span>me</span>
      </h2>

      {!isFormSubmitted ? (
        <div className="app__contact-form app__flex">
          <div className="app__flex">
            <input type="text" className="p-text" placeholder="Your Name" value={name} onChange={handleChangeInput} name="name" />
          </div>
          <div className="app__flex">
            <input type="email" className="p-text" placeholder="Your Email" value={email} onChange={handleChangeInput} name="email" />
          </div>
          <div className="app__flex">
            <input type="text" className="p-text" placeholder="Subject" value={subject} onChange={handleChangeInput} name="subject" />
          </div>
          <div>
            <textarea name="message" placeholder="Your Message" value={message} onChange={handleChangeInput} />
          </div>
          <button type="button" className=" portfolio-button" onClick={handleSubmit}>
            {loading ? "Sending Message" : "Send Message"}
          </button>
        </div>
      ) : (
        <div>
          <h3 className="head-text">
            Thank you for getting in <span>Touch!</span>
          </h3>
        </div>
      )}
    </>
  );
};

export default AppWrap(MotionWrap(Contact, "app__contact"), "contact", "app__primaryBg");

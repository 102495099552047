import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import { AppWrap, MotionWrap } from "../../wrapper";
import { client, urlFor } from "../../client";
import { images } from "../../constants";
import "./Skills.scss";

const Skills = () => {
  const [skills, setSkills] = useState([]);

  const skillVariants = {
    view: {
      x: [-15, 0],
      opacity: [0, 1],
      transition: {
        x: {
          type: "spring",
          stiffness: "10",
        },
        duration: 0.5,
        ease: "easeInOut",
      },
    },
    hover: {
      y: -7,
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
    tap: {
      y: -7,
      scale: 1.05,
      transition: {
        duration: 0.2,
        ease: "easeInOut",
      },
    },
  };

  useEffect(() => {
    const skillsQuery = '*[_type=="skills"] | order(proficiency desc)';
    client.fetch(skillsQuery).then((skillsData) => {
      setSkills(skillsData);
    });
  }, []);
  return (
    <>
      <h2 className="head-text">
        What <span>I</span> Do
      </h2>
      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {skills.map((skill, index) => (
            <motion.div variants={skillVariants} whileInView="view" whileHover="hover" whileTap="tap" className="app__skills-item app__flex" key={skill.name + "-" + index}>
              <div className="app__flex" style={{ backgroundColor: skill.bgColor }}>
                <img src={urlFor(skill.icon)} alt={skill.name} />
              </div>
              <p className="p-text">{skill.name}</p>
            </motion.div>
          ))}
        </motion.div>
        <motion.div className="app__skills-exp">
          <p className="bold-text">Full Stack Development</p>
          <ul>
            <li>Build responsive websites using Next.js and Laravel</li>
            <li>Build responsive Single Page Apps in React.js, Vue.js and Angular.js</li>
            <li>Build RESTful & GraphQL APIs in Express and PHP</li>
          </ul>

          <p className="bold-text">UX/UI Design</p>
          <ul>
            <li>Experience in designing user-friendly interfaces with Figma</li>
            <li>Experience in developing design systems and style guides</li>
            <li>Providing user-friendly design solutions</li>
          </ul>
          <div className="app__skills-img">
            <motion.img src={images.programmer} alt="profile_bg" whileInView={{ y: [-100, 0] }} />
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default AppWrap(MotionWrap(Skills, "app__skills"), "skills", "app__whitebg");

import React from "react";

import { BsLinkedin, BsGithub, BsHouseDoorFill } from "react-icons/bs";

const SocialMedia = () => {
  return (
    <div className="app__social">
      <div>
        <a href="https://www.linkedin.com/in/warrengalyen" target="_blank" rel="noreferrer" className="app__flex">
          <BsLinkedin />
        </a>
      </div>
      <div>
        <a href="https://www.github.com/warrengalyen" target="_blank" rel="noreferrer" className="app__flex">
          <BsGithub />
        </a>
      </div>
      <div>
        <a href="https://www.mechanikadesign.com" target="_blank" rel="noreferrer" className="app__flex">
          <BsHouseDoorFill />
        </a>
      </div>
    </div>
  );
};

export default SocialMedia;
